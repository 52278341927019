<template lang="">
  <div class="container player">
    <div class="close" @click="destroyplayer()">
      <img
        src="@/assets/icons/detail_close_icon.svg"
        alt="vlite-logo"
        loading="lazy"
      />
    </div>
    <video
      autoplay="true"
      controls
      id="litevideo"
      poster="@/assets/ok.svg"
    ></video>
  </div>
</template>
<script>
export default {
  props: ['content'],

  mounted() {
    if (this.content) {
      this.loadPlayer();
    }
  },
  /* max-width:100000px !important */
  watch: {
    content() {
      this.loadPlayer();
    },
    $route() {
      this.destroyplayer();
    },
  },

  methods: {
    destroyplayer() {
      // console.log('UNMOUNTING');
      hls.destroy();
      this.$emit('destroyed', this.content);
    },
    loadPlayer() {
      console.log('WATCHED CALLED', this.content);
      const source = this.content.contentlink;
      const video = document.getElementById('litevideo');

      const defaultOptions = {};

      if (!Hls.isSupported()) {
        video.src = source;
        var player = new Plyr(video, defaultOptions);
      } else {
        // For more Hls.js options, see https://github.com/dailymotion/hls.js
        const hls = new Hls();
        hls.loadSource(source);
        console.log('mounted', hls, source);

        // From the m3u8 playlist, hls parses the manifest and returns
        // all available video qualities. This is important, in this approach,
        // we will have one source on the Plyr player.
        hls.on(Hls.Events.MANIFEST_LOADED, function () {
            console.log('video and hls.js are now bound together !', video.duration);
        });
        video.addEventListener('loadeddata', function () {
          setTimeout(() => {
               video.currentTime = video.duration - 1;
            }, 10);
        })
        hls.on(Hls.Events.MANIFEST_PARSED, function(event, data) {
          // Transform available levels into an array of integers (height values).
          const availableQualities = hls.levels.map((l) => l.height);
          availableQualities.unshift(0); //prepend 0 to quality array

          defaultOptions.settings = ['quality'];

          defaultOptions.controls = [
            // 'play-large', // The large play button in the center
            // 'restart', // Restart playback
            // 'rewind', // Rewind by the seek time (default 10 seconds)
            'play', // Play/pause playback
            // 'fast-forward', // Fast forward by the seek time (default 10 seconds)
            'progress', // The progress bar and scrubber for playback and buffering
            // 'current-time', // The current time of playback
            // 'duration', // The full duration of the media
            'mute', // Toggle mute
            'volume', // Volume control
            'settings', // Settings menu
            'pip', // Picture-in-picture (currently Safari only)
            // 'airplay', // Airplay (currently Safari only)
            'fullscreen', // Toggle fullscreen
          ];

          defaultOptions.speed = {};

          // Add new qualities to option
          defaultOptions.quality = {
            default: 0, //Default - AUTO
            options: availableQualities,
            forced: true,
            onChange: (e) => updateQuality(e),
          };
          // Add Auto Label
          defaultOptions.i18n = {
            qualityLabel: {
              0: 'Auto',
            },
          };

          hls.on(Hls.Events.LEVEL_SWITCHED, function(event, data) {
            var span = document.querySelector(
              ".plyr__menu__container [data-plyr='quality'][value='0'] span"
            );
            if (hls.autoLevelEnabled) {
              span.innerHTML = `AUTO (${hls.levels[data.level].height}p)`;
            } else {
              span.innerHTML = `AUTO`;
            }
          });

          // Initialize new Plyr player with quality options
          console.log('VIDEO', video);
          var player = new Plyr(video, defaultOptions);
        });

        hls.attachMedia(video);
        window.hls = hls;

      }

      function updateQuality(newQuality) {
        console.error('1');
        if (newQuality === 0) {
          window.hls.currentLevel = -1; //Enable AUTO quality if option.value = 0
        } else {
          window.hls.levels.forEach((level, levelIndex) => {
            if (level.height === newQuality) {
              console.log('Found quality match with ' + newQuality);
              window.hls.currentLevel = levelIndex;
            }
          });
        }
      }
    },
  },
};
</script>
<style>

.container {
  margin-top: 0px !important;
  width: 100%;
}

.player {
  max-width: none;
  height: 100vh;
  background: black;

}
video {
  width: 100%;
  height: 100vh;
}

.plyr {
  width: 100%;
  height: 100vh;
}

.close {
  position: absolute;
  z-index: 100;
  right: 30px;
  top: 20px;
  cursor: pointer;
}
.close img {
  transform: scale(2);
}

:root {
  --plyr-color-main: #FF2C2C
}
</style>
